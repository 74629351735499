import { Heading, Flex } from '@pancakeswap/uikit'
import { BigNumber } from 'bignumber.js'
import Balance from 'components/Balance'
import { useCallback } from 'react'
import { useLpTokenPrice } from 'state/farms/hooks'
import { getBalanceAmount, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'

interface StackedLPProps {
  stakedBalance: BigNumber
  lpSymbol: string
  tokenSymbol: string
  quoteTokenSymbol: string
  lpTotalSupply: BigNumber
  tokenAmountTotal: BigNumber
  quoteTokenAmountTotal: BigNumber
}

const StakedLP: React.FunctionComponent<StackedLPProps> = ({
  stakedBalance,
  lpSymbol,
  quoteTokenSymbol,
  tokenSymbol,
  lpTotalSupply,
  tokenAmountTotal,
  quoteTokenAmountTotal,
}) => {
  const lpPrice = useLpTokenPrice(lpSymbol)

  const displayBalance = useCallback((tokenSymbol) => {
    const stakedBalanceBigNumber = getBalanceAmount(stakedBalance, tokenSymbol=='PGNFT'?0:10)
    if (stakedBalanceBigNumber.gt(0) && stakedBalanceBigNumber.lt(0.0000001)) {
      return stakedBalanceBigNumber.toFixed(10, BigNumber.ROUND_DOWN)
    }
    if (stakedBalanceBigNumber.gt(0) && stakedBalanceBigNumber.lt(0.0001)) {
      return getFullDisplayBalance(stakedBalance).toLocaleString()
    }
    return stakedBalanceBigNumber.toFixed(3, BigNumber.ROUND_DOWN)
  }, [stakedBalance])

  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <Heading color={stakedBalance.eq(0) ? 'textDisabled' : 'text'}>{displayBalance(tokenSymbol)}</Heading>
      {stakedBalance.gt(0) && (lpPrice.gt(0) || lpSymbol == 'PGNFT') && (
        <>
          <Balance
            fontSize="12px"
            color="textSubtle"
            decimals={2}
            value={(lpSymbol == 'PGNFT')?(new BigNumber(15e3).times(stakedBalance).toNumber()):getBalanceNumber(lpPrice.times(stakedBalance))}
            unit=" USD"
            prefix="~"
          />
          {
            (lpSymbol != 'PGNFT') && (
            <Flex style={{ gap: '4px' }}>
              <Balance
                fontSize="12px"
                color="textSubtle"
                decimals={2}
                value={stakedBalance.div(lpTotalSupply).times(tokenAmountTotal).toNumber()}
                unit={` ${tokenSymbol}`}
              />
              <Balance
                fontSize="12px"
                color="textSubtle"
                decimals={2}
                value={stakedBalance.div(lpTotalSupply).times(quoteTokenAmountTotal).toNumber()}
                unit={` ${quoteTokenSymbol}`}
              />
            </Flex>
            )
          }
        </>
      )}
    </Flex>
  )
}

export default StakedLP
